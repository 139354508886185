import React from 'react'
import { ButtonHTMLAttributes } from 'react'

interface IProps {
  dark?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  id?: string
  className?: string
}

const PrimitiveButton = (props: IProps) => {
  const { dark, onClick, children, type, disabled, id, className } = props
  const darkClass = dark
    ? 'bg-accent text-white shadow-accent-volume'
    : 'bg-white text-accent shadow-white-volume hover:text-white hover:shadow-accent-volume'
  const btnClass = `${darkClass} rounded-full py-3 px-8 text-center font-semibold transition-all hover:bg-accent-dark ${className}`
  return (
    <button
      className={btnClass}
      onClick={onClick}
      type={type}
      disabled={disabled}
      id={id}
    >
      {children}
    </button>
  )
}

export default PrimitiveButton
