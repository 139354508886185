import Link from 'next/link'
import { useState } from 'react'
import { useForm } from '@formspree/react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { dataForm } from '../../components/contact/dataForm'
import { PrimitiveButton } from '../primitive'

const ContactForm = () => {
  const [state, handleSubmit] = useForm('xknakgpv')
  const [emails, setEmail] = useState('')
  const [terminos, setTerminos] = useState(false)

  const onChangeTerminos = (e) => {
    setTerminos(e.target.checked)
  }

  const handleS = async (e) => {
    e.preventDefault()
    if (terminos === true) {
      try {
        await handleSubmit(e)
        toast.success('Correo enviado correctamente')
        e.target.reset()
      } catch (err) {
        toast.error('Error al enviar el correo')
      }
    }
    setTerminos(false)
  }

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleS} className='mb-20'>
        <input
          type='hidden'
          name='_subject'
          value={dataForm.subject + emails}
        />
        <input type='hidden' name='_replyto' value={atob(dataForm.mail)} />
        <div className='mb-6'>
          <label className='font-display text-jacarta-700 mb-1 block text-sm dark:text-white'>
            Nombre<span className='text-red'>*</span>
          </label>
          <input
            name='Nombre'
            className='w-full dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 rounded-lg py-3 hover:ring-2 dark:text-white'
            id='name'
            type='text'
            required
          />
        </div>
        <div className='mb-6'>
          <label className='font-display text-jacarta-700 mb-1 block text-sm dark:text-white'>
            Email<span className='text-red'>*</span>
          </label>
          <input
            name='Email'
            className='w-full dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 rounded-lg py-3 hover:ring-2 dark:text-white'
            id='email'
            type='email'
            required
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className='mb-4'>
          <label className='font-display text-jacarta-700 mb-1 block text-sm dark:text-white'>
            Mensaje<span className='text-red'>*</span>
          </label>
          <textarea
            id='message'
            className='w-full dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 rounded-lg py-3 hover:ring-2 dark:text-white'
            required
            name='Mensaje'
            rows={5}
          ></textarea>
        </div>
        <div className='mb-6 flex items-center md:space-x-2'>
          <input
            type='checkbox'
            required
            id='contact-form-consent-input'
            name='agree-to-terms'
            className='checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0 cursor-pointer'
            checked={terminos}
            onChange={onChangeTerminos}
          />
          <label className='dark:text-jacarta-200 text-sm'>
            Aceptar los{' '}
            <Link href='/terminos-condiciones' className='text-accent'>
              Términos de servicio
            </Link>
          </label>
        </div>
        <div className='mb-6 space-x-2 float-right '>
          <PrimitiveButton
            type='submit'
            id='contact-form-submit'
            disabled={state.submitting}
            dark={true}
          >
            Enviar
          </PrimitiveButton>
        </div>
        <div
          id='contact-form-notice'
          className='relative mt-4 hidden rounded-lg border border-transparent p-'
        ></div>
      </form>
    </>
  )
}

export default ContactForm
