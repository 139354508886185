/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import { values_data } from '../../data/goals_data'

const Promo = () => {
  const [videoModal, setVideoModal] = useState(false)
  const [url, setUrl] = useState(null)
  return (
    <>
      {/* <!-- Promo --> */}
      <section className='flex py-20  dark:bg-jacarta-800'>
        <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
          <img
            src='/images/gradient_light.jpg'
            alt='gradient'
            className='h-full w-full'
          />
        </picture>
        <div className='flex mx-auto p-5 container lay px-10 pl-3.5'>
          <div className='place-items-baseline lg:flex lg:justify-between'>
            {/* <!-- Image --> */}
            <div className='flex'>
              <div className='flex items-center space-x-8 '>
                <figure className='relative'>
                  <img
                    src='/images/crypto-consultant/R2_v.png'
                    className='rounded-3xl'
                    alt=''
                  />
                </figure>
                {/* <!-- Info --> */}
                <div className='px-15 lg:w-[45%] xl:ml-20'>
                  <h2 className='mb-6 font-display text-3xl text-jacarta-700 dark:text-white'>
                    Nuestros valores
                  </h2>
                  {values_data.map((item, index) => {
                    const { title, icon } = item
                    return (
                      <div className='flex space-x-4' key={`item-${index}`}>
                        <svg className='icon relative mb-6 h-8 w-8 fill-accent-dark dark:fill-white'>
                          <use xlinkHref={`/icons.svg#icon-${icon}`}></use>
                        </svg>
                        <div>
                          <span className='mb-3 block font-display text-base font-semibold text-jacarta-700 dark:text-white'>
                            {title}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <figure className='relative'>
                  <img
                    src='/images/crypto-consultant/R_valores.png'
                    alt=''
                    className='rounded-3xl'
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end promo --> */}
    </>
  )
}

export default Promo
