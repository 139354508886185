import React from 'react'
import { ContactForm, Address } from './'

const ContactHome = () => {
  const bgImage = '/images/page-title/knowledge_base_banner.jpg'
  return (
    <div className='md:lay'>
      {/* <!-- Contact --> */}
      <section className='dark:bg-transparent relative my-10'>
        <picture className='pointer-events-none absolute h-full inset-0 -z-10 dark:hidden'>
          <img
            src='/images/gradient_light.jpg'
            alt='gradient'
            className='h-full w-full'
          />
        </picture>
        <div className='container lg:flex py-10'>
          {/* <!-- Contact Form --> */}

          <div className='mb-12 lg:mb-0 lg:w-2/3 px-5 lg:pr-12 '>
            <h2 className='font-display text-jacarta-700 mb-4 text-xl dark:text-white tracking-wider'>
              Contáctanos
            </h2>

            <p className='dark:text-jacarta-300 mb-16 text-lg leading-normal'>
              {'  Nos interesa escucharte'}
            </p>

            <ContactForm />
          </div>

          {/* <!-- Info --> */}
          <Address />
        </div>
      </section>
      {/* <!-- end contact --> */}
    </div>
  )
}

export default ContactHome
