import React from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'

import { PrimitiveButton } from '@/components/primitive'

const Hero_7 = () => {
  const router = useRouter()
  const handlePostClick = () => {
    router.push(`/blog`)
  }
  const handleContactClick = () => {
    router.push(`/contact`)
  }
  return (
    <>
      {/* <!-- Hero --> */}
      <section className='relative pb-0 pt-20 lg:pt-48 mx-auto'>
        <picture className='pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden'>
          <Image
            src='/images/gradient.jpg'
            alt='gradient'
            width={1920}
            height={900}
            placeholder='blur'
            blurDataURL={'/images/gradient.jpg'}
          />
        </picture>
        <picture className='pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block'>
          <Image
            src='/images/gradient_dark.jpg'
            alt='gradient dark'
            width={1920}
            height={900}
            placeholder='blur'
            blurDataURL={'/images/gradient_dark.jpg'}
          />
        </picture>
        <Image
          src='/images/patterns/pattern_donut.png'
          alt='pattern donut'
          className='absolute right-0 top-0 -z-0'
          width={613}
          height={415}
          placeholder='blur'
          blurDataURL={'/images/patterns/pattern_donut.png'}
        />

        <div className='ml-auto mr-auto h-full max-w-[91rem] px-4 lay'>
          <div className='grid h-full items-center lg:grid-cols-12'>
            <div className='col-span-5 flex h-full flex-col items-center justify-center py-10 lg:items-start lg:py-20'>
              <h1 className='tracking-wide my-8 xl:my-2 text-center font-display text-5xl md:text-6xl text-jacarta-700 dark:text-white lg:text-left '>
                Romikya Labs Nuestra Identidad
              </h1>
              <p className='mb-10 xl:mb-20 max-w-md text-center text-rka lg:text-left'>
                Somos un laboratorio de software especializado en desarrollar
                sistemas robustos, tolerantes a fallos, que se enfoca en
                acelerar el desarrollo digital de sus socios tecnológicos.
              </p>
              <div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-2 mb-12'>
                <PrimitiveButton onClick={handleContactClick} dark={true}>
                  Contacto
                </PrimitiveButton>
                <PrimitiveButton onClick={handlePostClick}>
                  Blog
                </PrimitiveButton>
              </div>
            </div>

            {/* <!-- Hero image --> */}
            <div className='col-span-6 mx-auto'>
              <div className='relative text-center lg:pl-32 lg:text-right'>
                <img
                  src='/images/hero/R_nosotros.png'
                  alt='crypto consultant hero'
                  className='inline-block rounded-2.5xl'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  )
}

export default Hero_7
