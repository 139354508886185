export const goals_data = [
  {
    title: 'Consolidar un equipo especializado',
    desc: 'Consolidar un equipo especializado en desarrollo de software, usando la tecnología apropiada para solucionar efectivamente las necesidades. ',
    id: 'skilled-team',
  },
  {
    title: ' Desarrollar un proceso interno fiable',
    desc: 'Desarrollar un proceso interno fiable que impulse el talento e incremente exponencialmente la calidad de vida del equipo.',
    id: 'living-standard',
  },
  {
    title: 'Generar sinergia en el equipo',
    desc: 'Generar sinergia en el equipo para crear software sobresaliente en la industria y que ofrezca un valor real al usuario final sin comprometer su economía o seguridad.',
    id: 'team-synergy',
  },
  {
    title: 'Crear una carrera profesional para el equipo',
    desc: 'Crear una carrera profesional para el equipo, con el ideal de retener el mejor talento y contribuir con la formación de especialistas de software que tomen decisiones basándose en la experiencia de los más destacados de la industria y la propia, evitando reinventar la rueda.',
    id: 'career-path',
  },
  {
    title: 'Colaborar con el sistema de educación',
    desc: 'Colaborar con el sistema de educación nacional para contribuir con el crecimiento cultural y económico.',
    id: 'education-system',
  },
  {
    title: 'Transmitir la educación financiera',
    desc: 'Transmitir la educación financiera a todos los miembros de la empresa para compartir la meta de hacer productos sustentable, de impacto y atraer inversionistas que impulsen el desarrollo social, cultural y económico ',
    id: 'financial-education',
  },
]

export const values_data = [
  {
    title: 'Excelencia',
    desc: 'mostramos la excelncia',
    id: 'excelencia',
    icon: 'star',
  },
  {
    title: 'Amabilidad',
    desc: '',
    id: 'amabilidad',
    icon: 'user',
  },
  {
    title: 'Pertenencia',
    desc: '',
    id: 'pertenencia',
    icon: 'peoples',
  },
  {
    title: 'Transparencia',
    desc: '',
    id: 'transparencia',
    icon: 'heart',
  },
  {
    title: 'Ambición',
    desc: '',
    id: 'ambición',
    icon: 'stats-icon',
  },
]
