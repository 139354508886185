import React from 'react'

import Meta from '@/components/Meta'
import Hero_7 from '@/components/hero/hero_7'
import { Goals } from '@/components/home'
import Promo from '@/components/promo/promo'
import ContactHome from '@/components/contact/ContactHome'

const Home_7 = () => {
  return (
    <>
      <Meta title='Inicio' />
      <Hero_7 />
      <Goals />
      <Promo />
      <ContactHome />
    </>
  )
}

export default Home_7
