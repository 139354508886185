/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { goals_data } from '../../data/goals_data'

const Goals = () => {
  return (
    <div>
      {/* <!-- Services --> */}
      <section className='py-24 dark:bg-jacarta-900 lay'>
        <div className='container'>
          <div className='mx-auto mb-12 max-w-xl text-center'>
            <h2 className='mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white'>
              Visión
            </h2>
            <p className='text-rka dark:text-jacarta-300'>
              Simplificar las labores cotidianas mediante la innovación y el uso
              de la tecnología siguiendo las mejores prácticas y haciendo uso de
              la experiencia de profesionales apasionados por la tecnología.
            </p>
          </div>
          <div className='mx-auto mb-12 max-w-xl text-center'>
            <h2 className='mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white'>
              Objetivos
            </h2>
          </div>

          <div className='grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
            {goals_data.map((item) => {
              const { id, title, desc } = item
              return (
                <div
                  key={id}
                  className='service-item relative rounded-2.5xl border border-jacarta-100 bg-white p-10 pt-12 transition-shadow hover:shadow-xl dark:border-jacarta-700 dark:bg-jacarta-700'
                >
                  <span className='absolute top-10 left-12 block h-10 w-10 rounded-full bg-[#DCD0FF] dark:bg-accent'></span>
                  <svg className='icon relative mb-6 h-8 w-8 fill-accent-dark dark:fill-white'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className='h-8 w-8 shrink-0 fill-accent'
                    >
                      <path fill='none' d='M0 0h24v24H0z' />
                      <path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z' />
                    </svg>
                  </svg>
                  <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
                    {title}
                  </h3>
                  <p className='dark:text-jacarta-300'>{desc}</p>
                </div>
              )
            })}
          </div>

          <div className='mx-auto mb-12 max-w-xl text-center'>
            <h2 className='mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white'>
              Misión
            </h2>
            <p className='text-rka dark:text-jacarta-300'>
              Mejorar la calidad de vida de cada integrante del equipo,
              ofreciendo a los profesionales locales con talento y ambición
              apropiadas una alternativa viable para vivir con excelente calidad
              de vida haciendo lo que nos gusta.
            </p>
          </div>
        </div>
      </section>
      {/* <!-- end services --> */}
    </div>
  )
}

export default Goals
